@font-face {
  font-family: "Ploni";
  src: url(./fonts/ploni-regular-aaa_0.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Ploni";
  src: url(./fonts/ploni-medium-aaa_0.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Ploni";
  src: url(./fonts/ploni-demibold-aaa_0.otf) format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Ploni";
  src: url(./fonts/ploni-bold-aaa_0.otf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Ploni";
  src: url(./fonts/ploni-ultrabold-aaa_0.otf) format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Ploni";
  src: url(./fonts/ploni-black-aaa_0.otf) format("opentype");
  font-weight: 900;
}

body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
